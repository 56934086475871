import { all } from 'redux-saga/effects';

import Login from './loginSaga';
import Signup from './signupSaga';
import MyProfileSaga from './myProfileSaga';
import PositionSaga from './positionSaga';
import ProductSaga from './productSaga';
import InterviewSaga from './interviewSaga';
import RolesAndSubUserSaga from './rolesAndSubUserSaga';
import SupportSaga from './supportSaga';
import DashboardSaga from './dashboardSaga';
import PanelistSaga from './panelistSaga';
import CustomFieldSaga from './customFieldSaga';

export default function* rootSaga() {
    yield all([
        Login(),
        Signup(),
        MyProfileSaga(),
        PositionSaga(),
        ProductSaga(),
        InterviewSaga(),
        RolesAndSubUserSaga(),
        SupportSaga(),
        DashboardSaga(),
        PanelistSaga(),
        CustomFieldSaga(),
    ]);
}
