import {
    GET_COMPANY_DETAILS,
    GET_COMPANY_DETAILS_SUCCESS,
    GET_COMPANY_DETAILS_FAILURE,
    UPDATE_COMPANY_DETAILS,
    UPDATE_COMPANY_DETAILS_SUCCESS,
    UPDATE_COMPANY_DETAILS_FAILURE,
    GET_USER_INFORMATION,
    GET_USER_INFORMATION_SUCCESS,
    GET_USER_INFORMATION_FAILURE,
    UPDATE_USER_INFORMATION,
    UPDATE_USER_INFORMATION_SUCCESS,
    UPDATE_USER_INFORMATION_FAILURE,
    GET_INDUSTRY_TYPE,
    GET_INDUSTRY_TYPE_SUCCESS,
    GET_INDUSTRY_TYPE_FAILURE,
    GET_PAYMENT_METHOD,
    GET_PAYMENT_METHOD_SUCCESS,
    GET_PAYMENT_METHOD_FAILURE,
    DELETE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD_SUCCESS,
    DELETE_PAYMENT_METHOD_FAILURE,
    GET_SUBSCRIPTION_LIST,
    GET_SUBSCRIPTION_LIST_SUCCESS,
    GET_SUBSCRIPTION_LIST_FAILURE,
    ADD_PAYMENT_METHOD,
    ADD_PAYMENT_METHOD_SUCCESS,
    ADD_PAYMENT_METHOD_FAILURE,
    DELETE_USER,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    GET_WHITE_LABEL_DETAILS,
    GET_WHITE_LABEL_DETAILS_SUCCESS,
    GET_WHITE_LABEL_DETAILS_FAILURE,
    GET_DEEP_LINK_DATA,
    GET_DEEP_LINK_DATA_SUCCESS,
    GET_DEEP_LINK_DATA_FAILURE,
    SET_BOT_VOICE,
    SET_BOT_VOICE_SUCCESS,
    SET_BOT_VOICE_FAILURE,
    SET_WHITE_LABEL_LOGOS,
    SET_WHITE_LABEL_LOGOS_SUCCESS,
    SET_WHITE_LABEL_LOGOS_FAILURE,
    FETCH_LOGO,
    FETCH_LOGO_SUCCESS,
    FETCH_LOGO_FAILURE,
    TWO_FACTOR_AUTH,
    TWO_FACTOR_AUTH_SUCCESS,
    TWO_FACTOR_AUTH_FAILURE,
} from '../action/types';

const INIT_STATE = {
    loading: false,
    companyDetails: null,
    userInformation: null,
    industryType: null,
    paymentMethods: null,
    subscriptionList: null,
    whitelabelDetails: null,
    deepLinkData: null,
    logo: null,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TWO_FACTOR_AUTH:
            return { ...state, loading: false };
        case TWO_FACTOR_AUTH_SUCCESS:
            return { ...state, loading: false };
        case TWO_FACTOR_AUTH_FAILURE:
            return { ...state, loading: false };
        case FETCH_LOGO:
            return { ...state, loading: false };
        case FETCH_LOGO_SUCCESS:
            return { ...state, loading: false, logo: action.payload };
        case FETCH_LOGO_FAILURE:
            return { ...state, loading: false };

        case GET_WHITE_LABEL_DETAILS:
            return { ...state, loading: true };
        case GET_WHITE_LABEL_DETAILS_SUCCESS:
            return { ...state, loading: false, whitelabelDetails: action.payload };
        case GET_WHITE_LABEL_DETAILS_FAILURE:
            return { ...state, loading: false };
        case GET_COMPANY_DETAILS:
            return { ...state, loading: true };
        case GET_COMPANY_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                companyDetails: action.payload,
            };
        case GET_COMPANY_DETAILS_FAILURE:
            return { ...state, loading: false };

        case UPDATE_COMPANY_DETAILS:
            return { ...state, loading: true };
        case UPDATE_COMPANY_DETAILS_SUCCESS:
            return { ...state, loading: false };
        case UPDATE_COMPANY_DETAILS_FAILURE:
            return { ...state, loading: false };

        case GET_USER_INFORMATION:
            return { ...state, loading: true };
        case GET_USER_INFORMATION_SUCCESS:
            return {
                ...state,
                loading: false,
                userInformation: action.payload,
            };
        case GET_USER_INFORMATION_FAILURE:
            return { ...state, loading: false };

        case UPDATE_USER_INFORMATION:
            return { ...state, loading: true };
        case UPDATE_USER_INFORMATION_SUCCESS:
            return { ...state, loading: false };
        case UPDATE_USER_INFORMATION_FAILURE:
            return { ...state, loading: false };

        case GET_INDUSTRY_TYPE:
            return { ...state, loading: true };
        case GET_INDUSTRY_TYPE_SUCCESS:
            return { ...state, loading: false, industryType: action.payload };
        case GET_INDUSTRY_TYPE_FAILURE:
            return { ...state, loading: false };

        case GET_PAYMENT_METHOD:
            return { ...state, loading: true };
        case GET_PAYMENT_METHOD_SUCCESS:
            return { ...state, loading: false, paymentMethods: action.payload };
        case GET_PAYMENT_METHOD_FAILURE:
            return { ...state, loading: false };

        case DELETE_PAYMENT_METHOD:
            return { ...state, loading: true };
        case DELETE_PAYMENT_METHOD_SUCCESS:
            return { ...state, loading: false };
        case DELETE_PAYMENT_METHOD_FAILURE:
            return { ...state, loading: false };

        case GET_SUBSCRIPTION_LIST:
            return { ...state, loading: true };
        case GET_SUBSCRIPTION_LIST_SUCCESS:
            return { ...state, loading: false, subscriptionList: action.payload };
        case GET_SUBSCRIPTION_LIST_FAILURE:
            return { ...state, loading: false };
        case ADD_PAYMENT_METHOD:
            return { ...state, loading: true };
        case ADD_PAYMENT_METHOD_SUCCESS:
            return { ...state, loading: false };
        case ADD_PAYMENT_METHOD_FAILURE:
            return { ...state, loading: false };
        case DELETE_USER:
            return { ...state, loading: true };
        case DELETE_USER_SUCCESS:
            return { ...state, loading: false };
        case DELETE_USER_FAILURE:
            return { ...state, loading: false };
        case GET_DEEP_LINK_DATA:
            return { ...state, loading: true };
        case GET_DEEP_LINK_DATA_SUCCESS:
            return { ...state, loading: false, deepLinkData: action.payload };
        case GET_DEEP_LINK_DATA_FAILURE:
            return { ...state, loading: false };
        case SET_BOT_VOICE:
            return { ...state, loading: true };
        case SET_BOT_VOICE_SUCCESS:
            return { ...state, loading: false };
        case SET_BOT_VOICE_FAILURE:
            return { ...state, loading: false };
        case SET_WHITE_LABEL_LOGOS:
            return { ...state, loading: true };
        case SET_WHITE_LABEL_LOGOS_SUCCESS:
            return { ...state, loading: false };
        case SET_WHITE_LABEL_LOGOS_FAILURE:
            return { ...state, loading: false };
        default:
            return state;
    }
};
