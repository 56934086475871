import {
    GET_DOMAIN,
    GET_DOMAIN_SUCCESS,
    GET_DOMAIN_FAILURE,
    GET_MANDATORY_SKILL,
    GET_MANDATORY_SKILL_SUCCESS,
    GET_MANDATORY_SKILL_FAILURE,
    GET_OPTIONAL_SKILL,
    GET_OPTIONAL_SKILL_SUCCESS,
    GET_OPTIONAL_SKILL_FAILURE,
    GET_SCREENING_QUESTIONS,
    GET_SCREENING_QUESTIONS_SUCCESS,
    GET_SCREENING_QUESTIONS_FAILURE,
    DELETE_SCREENING_QUESTIONS,
    DELETE_SCREENING_QUESTIONS_SUCCESS,
    DELETE_SCREENING_QUESTIONS_FAILURE,
    CREATE_OR_UPDATE_POSITION,
    CREATE_OR_UPDATE_POSITION_SUCCESS,
    CREATE_OR_UPDATE_POSITION_FAILURE,
    GET_POSITION,
    GET_POSITION_SUCCESS,
    GET_POSITION_FAILURE,
    GET_POSITION_LIST,
    GET_POSITION_LIST_SUCCESS,
    GET_POSITION_LIST_FAILURE,
    DELETE_POSITION,
    DELETE_POSITION_SUCCESS,
    DELETE_POSITION_FAILURE,
    POSITIONAUTHERRORS,
    GET_SHORTLISTED_CANDIDATES,
    GET_SHORTLISTED_CANDIDATES_SUCCESS,
    GET_SHORTLISTED_CANDIDATES_FAILURE,
    GET_AI_SCREENING_QUESTIONS,
    GET_AI_SCREENING_QUESTIONS_SUCCESS,
    GET_AI_SCREENING_QUESTIONS_FAILURE,
    CREATE_JD,
    CREATE_JD_SUCCESS,
    CREATE_JD_FAILURE,
    COMPARE_JD_CV,
    COMPARE_JD_CV_SUCCESS,
    COMPARE_JD_CV_FAILURE,
    GET_DROPDOWN_POSITION,
    GET_DROPDOWN_POSITION_SUCCESS,
    GET_DROPDOWN_POSITION_FAILURE,
    CHANGE_POSITION_STATUS,
    CHANGE_POSITION_STATUS_SUCCESS,
    CHANGE_POSITION_STATUS_FAILURE,
    GET_GUEST_LIST,
    GET_GUEST_LIST_SUCCESS,
    GET_GUEST_LIST_FAILURE,
    GET_WHITELABEL,
    GET_WHITELABEL_SUCCESS,
    GET_WHITELABEL_FAILURE,
    MAKE_POSITION_FROM_INCBOT,
    MAKE_POSITION_FROM_INCBOT_SUCCESS,
    MAKE_POSITION_FROM_INCBOT_FAILURE,
} from './types';
export const compareJDCV = (payload) => ({
    type: COMPARE_JD_CV,
    payload,
});
export const compareJDCVSuccess = (payload) => ({
    type: COMPARE_JD_CV_SUCCESS,
    payload,
});
export const compareJDCVFailure = () => ({
    type: COMPARE_JD_CV_FAILURE,
});

export const getWhitelabel = () => ({
    type: GET_WHITELABEL,
});
export const getWhitelabelSuccess = (payload) => ({
    type: GET_WHITELABEL_SUCCESS,
    payload,
});
export const getWhitelabelFailure = () => ({
    type: GET_WHITELABEL_FAILURE,
});

export const createJD = (payload) => ({
    type: CREATE_JD,
    payload,
});
export const createJDSuccess = (payload) => ({
    type: CREATE_JD_SUCCESS,
    payload,
});
export const createJDFailure = () => ({
    type: CREATE_JD_FAILURE,
});

export const getDomain = () => ({
    type: GET_DOMAIN,
});
export const getDomainSuccess = (payload) => ({
    type: GET_DOMAIN_SUCCESS,
    payload,
});
export const getDomainFailure = () => ({
    type: GET_DOMAIN_FAILURE,
});

export const getPositionList = () => ({
    type: GET_POSITION_LIST,
});
export const getPositionListSuccess = (payload) => ({
    type: GET_POSITION_LIST_SUCCESS,
    payload,
});
export const getPositionListFailure = () => ({
    type: GET_POSITION_LIST_FAILURE,
});

export const getMandatorySkill = () => ({
    type: GET_MANDATORY_SKILL,
});
export const getMandatorySkillSuccess = (payload) => ({
    type: GET_MANDATORY_SKILL_SUCCESS,
    payload,
});
export const getMandatorySkillFailure = () => ({
    type: GET_MANDATORY_SKILL_FAILURE,
});

export const getOptionalSkill = () => ({
    type: GET_OPTIONAL_SKILL,
});
export const getOptionalSkillSuccess = (payload) => ({
    type: GET_OPTIONAL_SKILL_SUCCESS,
    payload,
});
export const getOptionalSkillFailure = () => ({
    type: GET_OPTIONAL_SKILL_FAILURE,
});

export const getScreeningQuestions = () => ({
    type: GET_SCREENING_QUESTIONS,
});
export const getScreeningQuestionsSuccess = (payload) => ({
    type: GET_SCREENING_QUESTIONS_SUCCESS,
    payload,
});
export const getScreeningQuestionsFailure = () => ({
    type: GET_SCREENING_QUESTIONS_FAILURE,
});

export const deleteScreeningQuestion = (payload) => ({
    type: DELETE_SCREENING_QUESTIONS,
    payload,
});
export const deleteScreeningQuestionSuccess = () => ({
    type: DELETE_SCREENING_QUESTIONS_SUCCESS,
});
export const deleteScreeningQuestionFailure = () => ({
    type: DELETE_SCREENING_QUESTIONS_FAILURE,
});

export const createOrUpdatePosition = (payload) => ({
    type: CREATE_OR_UPDATE_POSITION,
    payload,
});
export const createOrUpdatePositionSuccess = () => ({
    type: CREATE_OR_UPDATE_POSITION_SUCCESS,
});
export const createOrUpdatePositionFailure = (payload) => ({
    type: CREATE_OR_UPDATE_POSITION_FAILURE,
    payload,
});
export const inputAuthErrors = () => ({
    type: POSITIONAUTHERRORS,
});

export const getPosition = (payload) => ({
    type: GET_POSITION,
    payload,
});
export const getPositionSuccess = (payload) => ({
    type: GET_POSITION_SUCCESS,
    payload,
});
export const getPositionFailure = () => ({
    type: GET_POSITION_FAILURE,
});

export const changePositionStatus = (payload) => ({
    type: CHANGE_POSITION_STATUS,
    payload,
});
export const changePositionStatusSuccess = () => ({
    type: CHANGE_POSITION_STATUS_SUCCESS,
});
export const changePositionStatusFailure = () => ({
    type: CHANGE_POSITION_STATUS_FAILURE,
});

export const getDropdownPosition = (payload) => ({
    type: GET_DROPDOWN_POSITION,
    payload,
});
export const getDropdownPositionSuccess = (payload) => ({
    type: GET_DROPDOWN_POSITION_SUCCESS,
    payload,
});
export const getDropdownPositionFailure = () => ({
    type: GET_DROPDOWN_POSITION_FAILURE,
});

export const deletePosition = (payload) => ({
    type: DELETE_POSITION,
    payload,
});
export const deletePositionSuccess = () => ({
    type: DELETE_POSITION_SUCCESS,
});
export const deletePositionFailure = () => ({
    type: DELETE_POSITION_FAILURE,
});
export const getshortlistedCandidates = (payload) => ({
    type: GET_SHORTLISTED_CANDIDATES,
    payload,
});

export const getshortlistedCandidatesSuccess = (payload) => ({
    type: GET_SHORTLISTED_CANDIDATES_SUCCESS,
    payload,
});

export const getshortlistedCandidatesFailure = () => ({
    type: GET_SHORTLISTED_CANDIDATES_FAILURE,
});

export const getAiScreeningQuestions = (payload) => ({
    type: GET_AI_SCREENING_QUESTIONS,
    payload,
});
export const getAiScreeningQuestionsSuccess = (payload) => ({
    type: GET_AI_SCREENING_QUESTIONS_SUCCESS,
    payload,
});
export const getAiScreeningQuestionsFailure = () => ({
    type: GET_AI_SCREENING_QUESTIONS_FAILURE,
});

export const getGuestList = (payload) => ({
    type: GET_GUEST_LIST,
    payload,
});
export const getGuestListSuccess = (payload) => ({
    type: GET_GUEST_LIST_SUCCESS,
    payload,
});
export const getGuestListFailure = () => ({
    type: GET_GUEST_LIST_FAILURE,
});

export const makePositionFromIncbot = (payload) => ({
    type: MAKE_POSITION_FROM_INCBOT,
    payload,
});
export const makePositionFromIncbotSuccess = (payload) => ({
    type: MAKE_POSITION_FROM_INCBOT_SUCCESS,
    payload,
});
export const makePositionFromIncbotFailure = () => ({
    type: MAKE_POSITION_FROM_INCBOT_FAILURE,
});
