import React, { useState, useRef } from 'react';
import dropArrow from '../../assets/images/arrow-drop-white.svg';
import { useOutsideAlerter, Toast } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from './Button';

const SubUserDropDown = ({ products }) => {
    const [drop, setDrop] = useState(false);
    const ref = useRef();
    const navigate = useNavigate();
    useOutsideAlerter(ref, () => setDrop(false));
    const checkCredits = () => {
        toast.error(<Toast msg="Subuser limit exceeded." />, {
            toastId: 'Subuser limit exceeded.',
        });
    };
    const productsLength = products ? products.length : 0;

    return productsLength ? (
        productsLength === 1 ? (
            <Button
                text={'Invite Users'}
                className={'f-l f-sem-bol add-role-sub-btn common-btn mx-2'}
                isImage={false}
                onClick={() =>
                    navigate('/subuser', {
                        state: {
                            product: products[0],
                        },
                    })
                }
            />
        ) : (
            <div className="quick-create-container">
                <button
                    className={`f-l f-sem-bol quick-create-btn d-center rounded mx-2`}
                    onClick={() => setDrop(!drop)}
                >
                    <span>Invite Users</span>
                    <img src={dropArrow} className={'quick-arrow'} />
                </button>
                {drop && (
                    <div className="quick-dropdown rounded f-12 f-sem-bol" ref={ref}>
                        {products &&
                            products?.length > 0 &&
                            products?.map((itm, index) => (
                                <>
                                    <div
                                        key={index}
                                        className="quick-drop-option"
                                        onClick={() => {
                                            navigate('/subuser', {
                                                state: {
                                                    product: itm,
                                                },
                                            });
                                            setDrop(false);
                                        }}
                                    >
                                        {itm?.label}
                                    </div>
                                    <div className="quick-drop-divider"></div>
                                </>
                            ))}
                    </div>
                )}
            </div>
        )
    ) : (
        <Button
            text={'Invite Users'}
            className={'f-l f-sem-bol add-role-sub-btn common-btn ms-3 my-2'}
            isImage={false}
            onClick={() => checkCredits()}
        />
    );
};

export default SubUserDropDown;
