import { React, useState, useRef } from 'react';
import dropArrow from '../../assets/images/arrow-drop-white.svg';
import {
    useOutsideAlerter,
    hasActionAccess,
    localStorageDecrypt,
    localStorageJsonParseDecrypt,
} from '../../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { KEY_WORD } from '../../utils/constant';

const QuickCreate = ({ userPermissionList }) => {
    const currentProduct =
        localStorageDecrypt('productId') && localStorageJsonParseDecrypt('productId')?.productSubDomain?.toLowerCase();
    const [drop, setDrop] = useState(false);
    const ref = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    useOutsideAlerter(ref, () => setDrop(false));
    const positionCreateAccess = hasActionAccess(
        userPermissionList,
        currentProduct,
        KEY_WORD?.POSITIONS,
        KEY_WORD?.CREATE
    );
    const candidateCreateAccess = hasActionAccess(
        userPermissionList,
        currentProduct,
        KEY_WORD?.INTERVIEWS,
        KEY_WORD?.CREATE
    );

    return (
        <div className="quick-create-container">
            <button className={`f-l f-sem-bol quick-create-btn d-center rounded`} onClick={() => setDrop(!drop)}>
                <span>Quick Create</span>
                <img src={dropArrow} className="quick-arrow" alt="Arrow" />
            </button>
            {drop && (
                <div className="quick-dropdown rounded f-12 f-sem-bol" ref={ref}>
                    <div
                        title={!positionCreateAccess && 'Permission not enabled'}
                        className={`quick-drop-option ${positionCreateAccess ? 'quick-drop-option-enabled' : 'quick-drop-option-disabled'}`}
                        onClick={() => {
                            if (positionCreateAccess) {
                                if (location?.pathname === '/position') {
                                    navigate(location.pathname, {
                                        replace: true,
                                    });
                                    window.location.reload();
                                } else {
                                    navigate('/position');
                                }
                                setDrop(false);
                            }
                        }}
                    >
                        Create Position
                    </div>
                    <div className="quick-drop-divider"></div>
                    <div
                        className="quick-drop-option quick-drop-option-enabled"
                        onClick={() => {
                            if (location?.pathname === '/create-panelist') {
                                navigate(location.pathname, {
                                    replace: true,
                                });
                                window.location.reload();
                            } else {
                                navigate('/create-panelist');
                            }
                            setDrop(false);
                        }}
                    >
                        Add Panelist
                    </div>
                    <div className="quick-drop-divider"></div>
                    <div
                        title={!candidateCreateAccess && 'Permission not enabled'}
                        className={`quick-drop-option ${candidateCreateAccess ? 'quick-drop-option-enabled' : 'quick-drop-option-disabled'}`}
                        onClick={() => {
                            if (candidateCreateAccess) {
                                if (location?.pathname === '/post-interview') {
                                    navigate(location.pathname, {
                                        replace: true,
                                    });
                                    window.location.reload();
                                } else {
                                    navigate('/post-interview');
                                }
                                setDrop(false);
                            }
                        }}
                    >
                        Add Candidate
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuickCreate;
