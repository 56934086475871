import React, { memo, useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
    useOutsideAlerter,
    localStorageDecrypt,
    localStorageJsonParseDecrypt,
    hasPageAccess,
    incserveUrl,
    generateUrl,
    notificationRedirect,
    getIncserveToken,
} from '../../utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import appIcon from '../../assets/images/mobile-app-toggle-icon.svg';
import logoImg from '../../assets/images/incruiter-main-logo.svg';
import bellImg from '../../assets/images/bell.svg';
import Notify4 from '../../assets/images/notify4.svg';
import Notify3 from '../../assets/images/notify3.svg';
import Notify2 from '../../assets/images/notify2.svg';
import Notify1 from '../../assets/images/notify1.svg';
import { ReactComponent as MyProfile } from '../../assets/images/my-profile.svg';
import { ReactComponent as Support } from '../../assets/images/support.svg';
import { ReactComponent as Subuser } from '../../assets/images/subuser-group.svg';
import { ReactComponent as Logout } from '../../assets/images/logout.svg';
import { ReactComponent as Money } from '../../assets/images/payment-grey.svg';
import { ReactComponent as Settings } from '../../assets/images/settings.svg';
import { getNotification, clearNotification, readNotification, logoutUser } from '../../redux/action';
import { COMMON_PAGE_LIST, KEY_WORD, PageSize } from '../../utils/constant';
import close from '../../assets/images/close-notification.svg';
import dropArrow from '../../assets/images/my-profile/arrow-down.svg';
import { QuickCreate, Loader } from '../CommonComponent';

const Header = memo(
    ({ mobSidebar, setMobSidebar, userPermissionList, mainResource, companyData, userData, productDetails }) => {
        const currentProduct =
            localStorageDecrypt('productId') &&
            localStorageJsonParseDecrypt('productId')?.productSubDomain?.toLowerCase();
        const [openProfile, setOpenProfile] = useState(false);
        const [Notification, setNotification] = useState(false);
        const [companyObject, setCompanyObject] = useState(null);
        const [userObject, setUserObject] = useState(null);
        const [notify, setNotify] = useState();
        const navigate = useNavigate();
        const ref = useRef();
        const refNotification = useRef();
        const dispatch = useDispatch();
        const location = useLocation();
        const loading = useSelector((state) => state?.Dashboard?.loading);
        const notificationList = useSelector((state) => state?.Dashboard?.notificationList);
        const positionList = useSelector((state) => state?.Position?.positionList);
        const allPanelistDetail = useSelector((state) => state?.Panelist?.allPanelistDetails);
        const interviewList = useSelector((state) => state?.Interview?.interviewList);
        const subUserList = useSelector((state) => state?.RolesAndSubUser?.subUserList);
        const allRoleList = useSelector((state) => state?.RolesAndSubUser?.allRolesList);
        useOutsideAlerter(ref, () => setOpenProfile(false));
        useOutsideAlerter(refNotification, () => setNotification(false));
        useEffect(() => {
            dispatch(getNotification({ page: 1, pageSize: PageSize }));
        }, [dispatch, location?.pathname, positionList, allPanelistDetail, interviewList, subUserList, allRoleList]);

        useEffect(() => {
            setCompanyObject(companyData);
            setUserObject(userData);
        }, [companyData, userData]);

        useEffect(() => {
            notificationList?.data &&
                notificationList?.data?.length > 0 &&
                notificationList?.data?.map((data) => {
                    if (!data?.isRead) {
                        setNotify(true);
                    }
                });
        }, [notificationList]);

        useEffect(() => {
            setNotify(
                notificationList &&
                    notificationList?.data?.length > 0 &&
                    notificationList?.data?.filter((data) => data?.isRead === false)
            );
        }, [notificationList]);

        useEffect(() => {
            window.addEventListener('LOGO_URL_CHANGE', () => {
                setCompanyObject(localStorageJsonParseDecrypt('companyData'));
                setUserObject(localStorageJsonParseDecrypt('userData'));
            });
            window.addEventListener('COMPANY_DETAILS_CHANGE', () => {
                setCompanyObject(localStorageJsonParseDecrypt('companyData'));
                setUserObject(localStorageJsonParseDecrypt('userData'));
            });
        });

        const handleLogout = () => {
            dispatch(logoutUser());
        };

        const handleDeleteNotification = (data) => {
            if (data?._id) {
                dispatch(
                    clearNotification({
                        id: data?._id,
                        callback: () => dispatch(getNotification({ page: 1, pageSize: PageSize })),
                    })
                );
            }
        };

        const handleReadAllNotification = () => {
            dispatch(readNotification({}), setNotify(false), navigate('/notification'));
        };

        const handleClick = (currentProduct) => {
            setOpenProfile(false);
            if (currentProduct && ['InCFeed', 'InCVid'].includes(currentProduct?.productSubDomain)) {
                navigate('/plan-pricing', {
                    state: {
                        productId: currentProduct?.productId,
                        productFeatures: currentProduct?.productFeatures,
                        productName: currentProduct?.productName,
                        productSubDomain: currentProduct?.productSubDomain,
                    },
                });
            } else {
                navigate('/product');
            }
        };

        return (
            <>
                {loading && <Loader />}
                <nav className="navbar navbar-expand-lg header-main h-100">
                    <div className="h-100 w-100 p-25 my-xxl-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center h-100">
                            <div className="d-inline-block me-4 h-100">
                                <div className="hamburger d-flex align-items-center">
                                    <img
                                        src={appIcon}
                                        className="d-inline-block d-lg-none img-fluid menu-img"
                                        alt="apps"
                                        onClick={() => setMobSidebar(!mobSidebar)}
                                    />
                                    <img
                                        src={logoImg}
                                        className="d-none d-lg-inline-block header-main-logo"
                                        alt="log"
                                        onClick={() => navigate('/product')}
                                    />
                                </div>
                            </div>
                            {!COMMON_PAGE_LIST?.includes(location?.pathname) && (
                                <div className="d-none d-lg-inline-block me-4">
                                    <QuickCreate
                                        userObject={userObject}
                                        userPermissionList={userPermissionList}
                                        mainResource={mainResource}
                                        userData={userObject}
                                        productDetails={productDetails}
                                    />
                                </div>
                            )}
                        </div>
                        {window.innerWidth < 993 && (
                            <img
                                src={logoImg}
                                className="img-fluid h-75"
                                alt="log"
                                onClick={() => navigate('/product')}
                            />
                        )}
                        <div className="collapse navbar-collapse nav-position" id="navbarSupportedContent">
                            {window.location.pathname !== '/product' && localStorageDecrypt('productId') ? (
                                localStorageJsonParseDecrypt('productId')?.productSubDomain === 'InCServe' &&
                                getIncserveToken ? (
                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 header-navigation-links h-100 d-flex align-items-center">
                                        <li className={`d-inline-block f-l h-100 f-bol mx-4`}>
                                            <a
                                                href={`${incserveUrl}/employer-callback/${getIncserveToken}`}
                                                className="h-100 w-100 d-center"
                                                title="Go to Dashboard"
                                            >
                                                Dashboard
                                            </a>
                                        </li>
                                    </ul>
                                ) : localStorageJsonParseDecrypt('productId')?.productSubDomain === 'InCBot' ? (
                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 header-navigation-links h-100 d-flex align-items-center">
                                        <li className={`d-inline-block f-l h-100 f-bol mx-4`}>
                                            <a
                                                href={`${generateUrl('incbot')}/dashboard`}
                                                className="h-100 w-100 d-center"
                                                title="Go to Dashboard"
                                            >
                                                Dashboard
                                            </a>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0 header-navigation-links h-100 d-flex align-items-center">
                                        <li className={`d-inline-block f-l h-100 f-bol mx-4`}>
                                            <Link
                                                to={'/dashboard'}
                                                className={`h-100 w-100 d-center ${
                                                    location?.pathname === '/' || location?.pathname === '/dashboard'
                                                        ? 'active'
                                                        : '/'
                                                }`}
                                            >
                                                Dashboard
                                            </Link>
                                        </li>
                                        {!COMMON_PAGE_LIST?.includes(location?.pathname) &&
                                            hasPageAccess(
                                                userPermissionList,
                                                currentProduct,
                                                KEY_WORD?.INTERVIEWS,
                                                KEY_WORD?.VIEW
                                            ) && (
                                                <li className={`d-inline-block f-l h-100 me-4`}>
                                                    <Link
                                                        to={'/my-interviews'}
                                                        className={`h-100 w-100 d-center ${
                                                            location?.pathname === '/my-interviews' ? 'active' : '/'
                                                        }`}
                                                    >
                                                        Candidates
                                                    </Link>
                                                </li>
                                            )}
                                        {!COMMON_PAGE_LIST?.includes(location?.pathname) && (
                                            <li className={`d-inline-block me-4 f-l h-100`}>
                                                <Link
                                                    to={'/create-panelist'}
                                                    className={`h-100 w-100 d-center ${
                                                        location?.pathname === '/create-panelist' ? 'active' : '/'
                                                    }`}
                                                >
                                                    Panelists
                                                </Link>
                                            </li>
                                        )}
                                        {!COMMON_PAGE_LIST?.includes(location?.pathname) &&
                                            hasPageAccess(
                                                userPermissionList,
                                                currentProduct,
                                                KEY_WORD?.POSITIONS,
                                                KEY_WORD?.VIEW
                                            ) && (
                                                <li className={`d-inline-block f-l h-100`}>
                                                    <Link
                                                        to={'/my-positions'}
                                                        className={`h-100 w-100 d-center ${
                                                            location?.pathname === '/my-positions' ? 'active' : ''
                                                        }`}
                                                    >
                                                        Positions
                                                    </Link>
                                                </li>
                                            )}
                                    </ul>
                                )
                            ) : (
                                <></>
                            )}
                            <div
                                className={`d-inline-block position-relative mx-4 cursor-pointer`}
                                ref={refNotification}
                            >
                                <img
                                    src={bellImg}
                                    className="mr-3 img-fluid"
                                    alt="bell"
                                    style={{ width: '60%' }}
                                    onClick={() => setNotification(!Notification)}
                                    title="Notifications"
                                />
                                {notify && notify?.length > 0 && (
                                    <span className="count d-center text-theme-white f-sem-bol">
                                        {notify && notify?.length > 9 ? '9+' : notify?.length}
                                    </span>
                                )}
                                {Notification && (
                                    <div className="notification-dropdown position-absolute rounded d-flex flex-column">
                                        <div className="title rounded f-c f-sem-bol">
                                            You have {notify?.length} unread notifications
                                        </div>
                                        <div className="card-container">
                                            {notificationList?.data?.map(
                                                (data, index) =>
                                                    data?.isRead === false && (
                                                        <NotificationCard
                                                            data={data}
                                                            companyObject={companyObject}
                                                            key={index}
                                                            userObject={
                                                                userObject ?? localStorageJsonParseDecrypt('userData')
                                                            }
                                                            handleDeleteNotification={handleDeleteNotification}
                                                        />
                                                    )
                                            )}
                                        </div>
                                        <div
                                            className="view-all d-flex align-items-center justify-content-center"
                                            onClick={() => handleReadAllNotification()}
                                        >
                                            <span className="f-12 view-all-span cursor-pointer f-sem-bol">
                                                View All
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="user-profile-container position-relative" ref={ref}>
                                <div className="d-flex align-items-center" onClick={() => setOpenProfile(!openProfile)}>
                                    <div className="profile-img-container">
                                        <img
                                            src={
                                                userObject?.photo ||
                                                'https://incruiter-media.s3.ap-south-1.amazonaws.com/client1/emailTemplates/file-1659100896378-328095968.jpeg'
                                            }
                                            className="profile-img"
                                            alt="user"
                                        />
                                    </div>
                                    <div className="flex-grow-1 ms-1">
                                        <img src={dropArrow} className="img-fluid drop-arrow" alt="Arrow" />
                                    </div>
                                </div>
                                {openProfile && (
                                    <div
                                        className="profile-menu position-absolute rounded f-l f-bol"
                                        title={companyObject?.name}
                                    >
                                        {companyObject?.name && (
                                            <div className="ms-3">
                                                <div className="f-fh f-bol text-theme-blue company-name">
                                                    {companyObject?.name}
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            className="profile-menu-content d-flex align-items-center m-2 rounded"
                                            onClick={() => {
                                                setOpenProfile(false);
                                                navigate('/my-profile');
                                            }}
                                        >
                                            <MyProfile className="profile-menu-img img-fluid" />
                                            My Profile
                                        </div>
                                        {hasPageAccess(
                                            userPermissionList,
                                            KEY_WORD?.COMMON_PERMISSION,
                                            KEY_WORD?.ROLES_AND_SUBUSERS,
                                            KEY_WORD?.VIEW
                                        ) && (
                                            <div
                                                className="profile-menu-content d-flex align-items-center m-2 rounded"
                                                onClick={() => {
                                                    setOpenProfile(false);
                                                    navigate('/roles-subuser-list');
                                                }}
                                            >
                                                <Subuser className="profile-menu-img img-fluid" />
                                                Role / Sub User
                                            </div>
                                        )}
                                        {hasPageAccess(
                                            userPermissionList,
                                            KEY_WORD?.COMMON_PERMISSION,
                                            KEY_WORD?.ACCOUNTS_AND_BILLING,
                                            ''
                                        ) && (
                                            <div
                                                className="ab-menu-content d-flex align-items-center m-2 rounded"
                                                onClick={() => {
                                                    setOpenProfile(false);
                                                    navigate('/accounts-billing');
                                                }}
                                            >
                                                <Money className=" img-fluid ab-img" />
                                                Accounts & Billing
                                            </div>
                                        )}
                                        {hasPageAccess(
                                            userPermissionList,
                                            KEY_WORD?.COMMON_PERMISSION,
                                            KEY_WORD?.SUPPORT,
                                            ''
                                        ) && (
                                            <div
                                                className="profile-menu-content d-flex align-items-center m-2 rounded"
                                                onClick={() => {
                                                    setOpenProfile(false);
                                                    navigate('/support');
                                                }}
                                            >
                                                <Support className="profile-menu-img img-fluid" />
                                                Support
                                            </div>
                                        )}
                                        <div
                                            className="profile-menu-content d-flex align-items-center m-2 rounded"
                                            onClick={() => {
                                                setOpenProfile(false);
                                                navigate('/settings');
                                            }}
                                        >
                                            <Settings className="img-fluid logout" />
                                            Settings / Preferences
                                        </div>
                                        <div
                                            className="profile-menu-content d-flex align-items-center m-2 rounded"
                                            onClick={handleLogout}
                                        >
                                            <Logout className="img-fluid logout" />
                                            Logout
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
);

export const NotificationCard = memo(({ data, handleDeleteNotification, userObject }) => {
    const navigate = useNavigate();
    const productData = useSelector((state) => state.Product.productData);

    return (
        <div
            className="notification-card d-flex align-items-center position-relative rounded"
            onClick={() => notificationRedirect(data, productData, navigate)}
        >
            <img
                src={
                    data?.type === 'interview_schedule'
                        ? Notify1
                        : data?.type === 'interview_reschedule'
                          ? Notify1
                          : data?.type === 'interview_cancelled'
                            ? Notify2
                            : data?.type === 'interview_completed'
                              ? Notify3
                              : Notify4
                }
                alt="Notification"
                className="notify-img"
            />
            <div className="d-flex message-container flex-column w-100">
                <span className="f-l f-sem-bol content-title">
                    {data?.type === 'interview_schedule'
                        ? 'Interview Scheduled'
                        : data?.type === 'interview_reschedule'
                          ? 'Interview Rescheduled'
                          : data?.type === 'interview_cancelled'
                            ? 'Interview Cancelled'
                            : data?.type === 'interview_completed'
                              ? 'Interview Completed'
                              : `Hello ${userObject?.name}!`}
                </span>
                <span className="f-ph f-med content-name">{data?.message}</span>
            </div>
            <img
                className="notification-close position-absolute cursor-pointer"
                onClick={() => handleDeleteNotification(data)}
                src={close}
                alt="Close Notification"
            />
        </div>
    );
});
export default Header;
