import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Loader } from '../../component/CommonComponent';
import { localStorageEncrypt, hasProductAccess, incserveUrl, generateUrl, getIncserveToken } from '../../utils/helper';
import profileUpdate from '../../assets/images/profile-update.svg';
import { callBackRequest, getConfigurationAutoInvite } from '../../redux/action';
import ProductCard from '../../component/ProductAndPlansComponents/ProductCard';
import greenTick from '../../assets/images/product-round-tick.svg';
import moment from 'moment';

const Product = memo(({ productLoading, productDetails, userPermissionList, userData, companyData, activeSidebar }) => {
    const navigate = useNavigate();
    const loadingMyProfile = useSelector((state) => state.MyProfile.loading);
    const subscriptionList = useSelector((state) => state.MyProfile.subscriptionList);
    const [popup, setPopup] = useState(false);
    const [profilePopup, setProfilePopup] = useState(false);
    const [profileData, setProfileData] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [companyDetails, setCompanyDetails] = useState(null);
    const dispatch = useDispatch();
    const handleEnquiryRequest = async (payload) => {
        if (payload.message) {
            dispatch(
                callBackRequest({
                    message: payload.message,
                })
            );
        }
    };
    useEffect(() => {
        setUserDetails(userData);
        setCompanyDetails(companyData);
    }, [userData, companyData]);

    useEffect(() => {
        dispatch(getConfigurationAutoInvite());
    }, []);

    useEffect(() => {
        let result;
        if (subscriptionList) {
            for (let index = 0; index < subscriptionList.length; index++) {
                if (!subscriptionList[index]?.product?.name) {
                    result = productDetails.find((product) => product.includes(subscriptionList[index]?.product));
                    subscriptionList[index].product = result;
                }
            }
        }
    }, [subscriptionList]);

    const handleClick = (data) => {
        navigate('/plan-pricing', {
            state: {
                productId: data?._id,
                productFeatures: data?.productFeatures,
                productName: data?.name,
                productSubDomain: data?.subdomain,
            },
        });
    };
    const handleDashboard = (data, status) => {
        localStorageEncrypt(
            'productId',
            JSON.stringify({
                productId: data?._id,
                isActive: data?.status,
                productSubDomain: data?.subdomain,
                productFeatures: data?.productHighlights,
                productName: data?.name,
            })
        );
        if (status) navigate('/dashboard');
    };

    const OurProducts = useMemo(() => {
        return productDetails;
    }, [productDetails]);

    const MySubscriptions = useMemo(() => {
        if (userPermissionList?.length > 0) {
            return productDetails?.filter(
                (cardData) =>
                    cardData.status !== 'Unsubscribed' &&
                    hasProductAccess(userPermissionList, cardData?.name.toLowerCase())
            );
        }
        return productDetails?.filter((cardData) => cardData.status !== 'Unsubscribed');
    }, [productDetails, userPermissionList]);

    return (
        <>
            {(productLoading || loadingMyProfile) && <Loader />}
            <div className="landingContainer">
                <div className="landing-middle">
                    <div className="lp-products f-bol text-center mb-4 mt-1">Dashboard</div>
                    <div className="landing-title-box input-shadow">
                        <div className="ltb-1">
                            <div className="ltb-1-title f-fh f-bol">Hello {companyDetails?.company?.name}!</div>
                            <div className="ltb-1-subtitle f-ph f-sem-bol">Welcome, you’re doing great.</div>
                        </div>
                    </div>
                    {MySubscriptions?.length > 0 && (
                        <div>
                            <div className="lp-products f-bol text-center mb-4">My Subscriptions</div>
                            <div className="subscription-box">
                                {MySubscriptions?.map((cardData, index) => (
                                    <div
                                        className={`subscription-card ${index === MySubscriptions.length - 1 ? 'mb-0' : ''}`}
                                        key={index}
                                    >
                                        <div className="subscription-detail d-flex">
                                            <img
                                                src={cardData?.icon}
                                                alt="Product Icon"
                                                className={'subscription-card-icon'}
                                            />
                                            <div className="ms-3">
                                                <div className="f-sem-bol f-16">
                                                    {cardData.name}:{cardData.label}
                                                </div>
                                                {cardData?.validTill && (
                                                    <div className="f-sem-bol f-10 text-theme-gray">
                                                        Valid Till : {moment(cardData?.validTill).format('ll')}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="dashboard-btn-box">
                                            {cardData.subdomain === 'InCServe' && getIncserveToken ? (
                                                <a
                                                    href={`${incserveUrl}/employer-callback/${getIncserveToken}`}
                                                    className="f-sem-bol f-12 lpc-round lpc-btn lpc-blue"
                                                    title="Go to Dashboard"
                                                    onClick={() => handleDashboard(cardData, false)}
                                                >
                                                    Go to Dashboard
                                                </a>
                                            ) : cardData.subdomain === 'InCBot' ? (
                                                <a
                                                    href={`${generateUrl('incbot')}/dashboard`}
                                                    className="f-sem-bol f-12 lpc-round lpc-btn lpc-blue"
                                                    title="Go to Dashboard"
                                                    onClick={() => handleDashboard(cardData, false)}
                                                >
                                                    Go to Dashboard
                                                </a>
                                            ) : (
                                                <Button
                                                    text={'Go to Dashboard'}
                                                    className="f-sem-bol f-12 lpc-round lpc-btn lpc-blue"
                                                    onClick={() => handleDashboard(cardData, true)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {OurProducts?.length > 0 && (
                        <>
                            <div className="lp-products f-bol text-center mb-4">Our Products</div>
                            <div className={`${!activeSidebar ? 'lp-card-3' : ''} lp-card-container`}>
                                {OurProducts.map((cardData, index) => (
                                    <ProductCard
                                        key={index}
                                        productDetail={cardData}
                                        onClick={() => handleClick(cardData)}
                                        popup={popup}
                                        setPopup={setPopup}
                                        sendContactEnquiry={handleEnquiryRequest}
                                        userData={userDetails}
                                        setProfilePopup={setProfilePopup}
                                        setProfileData={setProfileData}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
            {profilePopup && (
                <ProductDashboardPopup
                    img={profileUpdate}
                    title={'Welcome aboard!'}
                    subTitle={`Please update your profile
              to continue with the subscription`}
                    btnName={'Update Profile'}
                    btnClass={'common-btn f-c f-sem-bol pu-btn'}
                    onClick={() =>
                        navigate('/my-profile', {
                            state: { data: profileData },
                        })
                    }
                />
            )}
            {popup && (
                <ProductDashboardPopup
                    img={greenTick}
                    subTitle={
                        'Your product inquiry has been sent to our Sales Team. Our Sales Team will get back to you shortly.'
                    }
                    btnName={'Okay'}
                    btnClass={'ok-btn f-14 f-sem-bol rounded-pill'}
                    onClick={() => setPopup(!popup)}
                />
            )}
        </>
    );
});

export default Product;

export const ProductDashboardPopup = ({ img, subTitle, btnName, btnClass, onClick, title }) => {
    return (
        <div className="delete-popup">
            <div className="pu-popup-box d-flex flex-column align-items-center rounded">
                <img src={img} alt="profile-update" className="profile-update img-fluid" />
                <div className="mt-2 f-40 f-med">{title}</div>
                <div className="pu-subtitle f-18 f-reg">{subTitle}</div>
                <Button text={btnName} className={btnClass} onClick={onClick} />
            </div>
        </div>
    );
};
