import {
    POST_INTERVIEW,
    POST_INTERVIEW_SUCCESS,
    POST_INTERVIEW_FAILURE,
    POST_BULK_INTERVIEW,
    POST_BULK_INTERVIEW_SUCCESS,
    POST_BULK_INTERVIEW_FAILURE,
    GET_INTERVIEW,
    GET_INTERVIEW_SUCCESS,
    GET_INTERVIEW_FAILURE,
    GET_INTERVIEW_STAT,
    GET_INTERVIEW_STAT_SUCCESS,
    GET_INTERVIEW_STAT_FAILURE,
    GET_POSITION_STAT,
    GET_POSITION_STAT_SUCCESS,
    GET_POSITION_STAT_FAILURE,
    UPDATE_INTERVIEW_STATUS,
    UPDATE_INTERVIEW_STATUS_SUCCESS,
    UPDATE_INTERVIEW_STATUS_FAILURE,
    GET_FEEDBACK,
    GET_FEEDBACK_SUCCESS,
    GET_FEEDBACK_FAILURE,
    CREATE_CALENDAR_INVITATION,
    CREATE_CALENDAR_INVITATION_SUCCESS,
    CREATE_CALENDAR_INVITATION_FAILURE,
    GET_FEEDBACK_REPORT,
    GET_FEEDBACK_REPORT_SUCCESS,
    GET_FEEDBACK_REPORT_FAILURE,
    GET_INTERVIEW_BY_ID,
    GET_INTERVIEW_BY_ID_SUCCESS,
    GET_INTERVIEW_BY_ID_FAILURE,
    GET_INTERVIEW_BY_ID_FOR_POSITION,
    GET_INTERVIEW_BY_ID_FOR_POSITION_SUCCESS,
    GET_INTERVIEW_BY_ID_FOR_POSITION_FAILURE,
    CHECK_CANDIDATE_EXISTS,
    CHECK_CANDIDATE_EXISTS_SUCCESS,
    CHECK_CANDIDATE_EXISTS_FAILURE,
    GET_PANELIST_CALENDAR_REQUEST,
    GET_PANELIST_CALENDAR_REQUEST_SUCCESS,
    GET_PANELIST_CALENDAR_REQUEST_FAILURE,
    GET_CANDIDATE_CALENDAR_REQUEST,
    GET_CANDIDATE_CALENDAR_REQUEST_SUCCESS,
    GET_CANDIDATE_CALENDAR_REQUEST_FAILURE,
    GET_CANDIDATE_DETAILS_USING_RESUME,
    GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS,
    GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE,
    ADD_CUSTOM_FILE,
    ADD_CUSTOM_FILE_SUCCESS,
    ADD_CUSTOM_FILE_FAILURE,
    RESCHEDULE_ERROR,
    RESCHEDULE_ERROR_SUCCESS,
    RESCHEDULE_ERROR_ERROR,
    CONTINUE_CALENDAR_INVITATION,
    CONTINUE_CALENDAR_INVITATION_SUCCESS,
    CONTINUE_CALENDAR_INVITATION_FAILURE,
    GET_POSITION_STAT_FOR_BAR_GRAPH,
    GET_POSITION_STAT_FOR_BAR_GRAPH_SUCCESS,
    GET_POSITION_STAT_FOR_BAR_GRAPH_FAILURE,
    GET_CANDIDATE_STAT,
    GET_CANDIDATE_STAT_SUCCESS,
    GET_CANDIDATE_STAT_FAILURE,
    RESEND_EMAIL,
    RESEND_EMAIL_SUCCESS,
    RESEND_EMAIL_FAILURE,
    FETCH_RESUME_SCORE,
    FETCH_RESUME_SCORE_SUCCESS,
    FETCH_RESUME_SCORE_FAILURE,
    GET_INCVID_PLATFORM_PERMISSION,
    GET_INCVID_PLATFORM_PERMISSION_SUCCESS,
    GET_INCVID_PLATFORM_PERMISSION_FAILURE,
    GET_INTERVIEW_INCBOT,
    GET_INTERVIEW_INCBOT_SUCCESS,
    GET_INTERVIEW_INCBOT_FAILURE,
} from './types';
export const fetchResumeScore = (payload) => ({
    type: FETCH_RESUME_SCORE,
    payload,
});
export const fetchResumeScoreSuccess = (payload) => ({
    type: FETCH_RESUME_SCORE_SUCCESS,
    payload,
});
export const fetchResumeScoreFailure = () => ({
    type: FETCH_RESUME_SCORE_FAILURE,
});
export const getCandidateDetailsUsingResume = (payload) => ({
    type: GET_CANDIDATE_DETAILS_USING_RESUME,
    payload,
});
export const getCandidateDetailsUsingResumeSuccess = (payload) => ({
    type: GET_CANDIDATE_DETAILS_USING_RESUME_SUCCESS,
    payload,
});
export const getCandidateDetailsUsingResumeFailure = () => ({
    type: GET_CANDIDATE_DETAILS_USING_RESUME_FAILURE,
});
export const getPanelistCalendarRequest = (payload) => ({
    type: GET_PANELIST_CALENDAR_REQUEST,
    payload,
});
export const getPanelistCalendarRequestSuccess = (payload) => ({
    type: GET_PANELIST_CALENDAR_REQUEST_SUCCESS,
    payload,
});
export const getPanelistCalendarRequestFailure = () => ({
    type: GET_PANELIST_CALENDAR_REQUEST_FAILURE,
});

export const getCandidateCalendarRequest = (payload) => ({
    type: GET_CANDIDATE_CALENDAR_REQUEST,
    payload,
});
export const getCandidateCalendarRequestSuccess = (payload) => ({
    type: GET_CANDIDATE_CALENDAR_REQUEST_SUCCESS,
    payload,
});
export const getCandidateCalendarRequestFailure = () => ({
    type: GET_CANDIDATE_CALENDAR_REQUEST_FAILURE,
});

export const postInterview = (payload) => ({
    type: POST_INTERVIEW,
    payload,
});
export const postInterviewSuccess = () => ({
    type: POST_INTERVIEW_SUCCESS,
});
export const postInterviewFailure = (payload) => ({
    type: POST_INTERVIEW_FAILURE,
    payload,
});

export const postBulkInterview = (payload) => ({
    type: POST_BULK_INTERVIEW,
    payload,
});
export const postBulkInterviewSuccess = (payload) => ({
    type: POST_BULK_INTERVIEW_SUCCESS,
    payload,
});
export const postBulkInterviewFailure = (payload) => ({
    type: POST_BULK_INTERVIEW_FAILURE,
    payload,
});

export const getInterviewStat = (payload) => ({
    type: GET_INTERVIEW_STAT,
    payload,
});
export const getInterviewStatSuccess = (payload) => ({
    type: GET_INTERVIEW_STAT_SUCCESS,
    payload,
});
export const getInterviewStatFailure = () => ({
    type: GET_INTERVIEW_STAT_FAILURE,
});

export const getPositionStat = (payload) => ({
    type: GET_POSITION_STAT,
    payload,
});
export const getPositionStatSuccess = (payload) => ({
    type: GET_POSITION_STAT_SUCCESS,
    payload,
});
export const getPositionStatFailure = () => ({
    type: GET_POSITION_STAT_FAILURE,
});

export const getCandidateStat = (payload) => ({
    type: GET_CANDIDATE_STAT,
    payload,
});
export const getCandidateStatSuccess = (payload) => ({
    type: GET_CANDIDATE_STAT_SUCCESS,
    payload,
});
export const getCandidateStatFailure = () => ({
    type: GET_CANDIDATE_STAT_FAILURE,
});

export const getPositionStatForBarGraph = (payload) => ({
    type: GET_POSITION_STAT_FOR_BAR_GRAPH,
    payload,
});
export const getPositionStatForBarGraphSuccess = (payload) => ({
    type: GET_POSITION_STAT_FOR_BAR_GRAPH_SUCCESS,
    payload,
});
export const getPositionStatForBarGraphFailure = () => ({
    type: GET_POSITION_STAT_FOR_BAR_GRAPH_FAILURE,
});

export const getInterview = (payload) => ({
    type: GET_INTERVIEW,
    payload,
});
export const getInterviewSuccess = (payload) => ({
    type: GET_INTERVIEW_SUCCESS,
    payload,
});
export const getInterviewFailure = () => ({
    type: GET_INTERVIEW_FAILURE,
});

export const getInterviewById = (payload) => ({
    type: GET_INTERVIEW_BY_ID,
    payload,
});
export const getInterviewByIdSuccess = (payload) => ({
    type: GET_INTERVIEW_BY_ID_SUCCESS,
    payload,
});
export const getInterviewByIdFailure = () => ({
    type: GET_INTERVIEW_BY_ID_FAILURE,
});

export const getInterviewByIdForPosition = (payload) => ({
    type: GET_INTERVIEW_BY_ID_FOR_POSITION,
    payload,
});
export const getInterviewByIdForPositionSuccess = (payload) => ({
    type: GET_INTERVIEW_BY_ID_FOR_POSITION_SUCCESS,
    payload,
});
export const getInterviewByIdForPositionFailure = () => ({
    type: GET_INTERVIEW_BY_ID_FOR_POSITION_FAILURE,
});

export const updateInterviewStatus = (payload) => ({
    type: UPDATE_INTERVIEW_STATUS,
    payload,
});
export const updateInterviewStatusSuccess = () => ({
    type: UPDATE_INTERVIEW_STATUS_SUCCESS,
});
export const updateInterviewStatusFailure = () => ({
    type: UPDATE_INTERVIEW_STATUS_FAILURE,
});

export const getFeedback = (payload) => ({
    type: GET_FEEDBACK,
    payload,
});
export const getFeedbackSuccess = (payload) => ({
    type: GET_FEEDBACK_SUCCESS,
    payload,
});
export const getFeedbackFailure = () => ({
    type: GET_FEEDBACK_FAILURE,
});

export const createCalendarInvite = (payload) => ({
    type: CREATE_CALENDAR_INVITATION,
    payload,
});
export const createCalendarInviteSuccess = () => ({
    type: CREATE_CALENDAR_INVITATION_SUCCESS,
});
export const createCalendarInviteFailure = () => ({
    type: CREATE_CALENDAR_INVITATION_FAILURE,
});

export const continueCalendarInvite = (payload) => ({
    type: CONTINUE_CALENDAR_INVITATION,
    payload,
});
export const continueCalendarInviteSuccess = () => ({
    type: CONTINUE_CALENDAR_INVITATION_SUCCESS,
});
export const continueCalendarInviteFailure = () => ({
    type: CONTINUE_CALENDAR_INVITATION_FAILURE,
});

export const getFeedbackReport = (payload) => ({
    type: GET_FEEDBACK_REPORT,
    payload,
});
export const getFeedbackReportSuccess = (payload) => ({
    type: GET_FEEDBACK_REPORT_SUCCESS,
    payload,
});
export const getFeedbackReportFailure = (payload) => ({
    type: GET_FEEDBACK_REPORT_FAILURE,
    payload,
});
export const checkCandidateExists = (payload) => ({
    type: CHECK_CANDIDATE_EXISTS,
    payload,
});
export const checkCandidateExistsSuccess = (payload) => ({
    type: CHECK_CANDIDATE_EXISTS_SUCCESS,
    payload,
});
export const checkCandidateExistsFailure = () => ({
    type: CHECK_CANDIDATE_EXISTS_FAILURE,
});

export const addCustomFile = (payload) => ({
    type: ADD_CUSTOM_FILE,
    payload,
});
export const addCustomFileSuccess = (payload) => ({
    type: ADD_CUSTOM_FILE_SUCCESS,
    payload,
});
export const addCustomFileFailure = (payload) => ({
    type: ADD_CUSTOM_FILE_FAILURE,
    payload,
});

export const resendEmail = (payload) => ({
    type: RESEND_EMAIL,
    payload,
});
export const resendEmailSuccess = () => ({
    type: RESEND_EMAIL_SUCCESS,
});
export const resendEmailFailure = () => ({
    type: RESEND_EMAIL_FAILURE,
});

export const getInterviewIncbot = (payload) => ({
    type: GET_INTERVIEW_INCBOT,
    payload,
});
export const getInterviewIncbotSuccess = (payload) => ({
    type: GET_INTERVIEW_INCBOT_SUCCESS,
    payload,
});
export const getInterviewIncbotFailure = () => ({
    type: GET_INTERVIEW_INCBOT_FAILURE,
});

export const incvidPlatformPermission = (payload) => ({
    type: GET_INCVID_PLATFORM_PERMISSION,
    payload,
});
export const incvidPlatformPermissionSuccess = (payload) => ({
    type: GET_INCVID_PLATFORM_PERMISSION_SUCCESS,
    payload,
});
export const incvidPlatformPermissionFailure = (payload) => ({
    type: GET_INCVID_PLATFORM_PERMISSION_FAILURE,
    payload,
});
